import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faRss,
  faUser,
  faChevronRight,
  faSpinner,
  faMugHot,
  faAtom,
  faPodcast,
  faTimes,
  faArrowUpRightFromSquare,
  faLink,
  faGraduationCap,
  faPersonChalkboard,
  faNewspaper,
  faMicrophone,
  faShop,
} from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import {
  faGithub,
  faXTwitter,
  faInstagram,
  faLinkedin,
  faTwitch,
  faAmazon,
  faYoutube,
  faSpotify,
  faMeetup,
} from '@fortawesome/free-brands-svg-icons'

import './src/styles/tailwind.css'
import './src/styles/index.css'
import '@fontsource-variable/inter'

library.add(
  faRss,
  faUser,
  faAtom,
  faChevronRight,
  faCheckCircle,
  faSpinner,
  faGithub,
  faXTwitter,
  faInstagram,
  faLinkedin,
  faTwitch,
  faYoutube,
  faAmazon,
  faSpotify,
  faPodcast,
  faMugHot,
  faTimes,
  faArrowUpRightFromSquare,
  faLink,
  faGraduationCap,
  faPersonChalkboard,
  faMeetup,
  faNewspaper,
  faMicrophone,
  faShop,
)
